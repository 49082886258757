.variable-vault-list-item-view-row{
    // background-color:pink;
    margin-top:5px;
    padding-top:5px;
    border-top:1px solid #eee;

    .variable-vault-list-item-view-key-col{
        // background-color:green;
        padding:10px 0 0 15px;
    }

    .variable-vault-list-item-view-key-value-col{
        // background-color:blue;
        padding:10px 0 0 15px;
        white-space:nowrap;
        overflow:hidden;

        i{
            position:relative;
            top:-5px;
            margin-right:5px;
        }

        span{
            font-size:125%;
        }
    }

    .variable-vault-list-item-view-action-col{
        white-space:nowrap;
        visibility:hidden;
    }

}
.variable-vault-list-item-view-row:hover{
    .variable-vault-list-item-view-action-col{
        visibility:visible;
    }
}
