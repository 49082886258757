.executionLogLinesContainer{
    background-colorx:pink;
    padding-bottom:0px;

    .log_line_item_container{
        background-colorx:orange;
        margin-bottom:20px;
        margin-top:0px;
        margin-right:20px;
        position:relative;
        padding-left:50px;
        left:0px;

        .vertLineContainer{
            background-color:red;
            position:absolute;
            left:22px;
            top:2px;
            bottom:-22px;
            border-left:3px solid #EEEEEE;
        }

        .log-line-icon{
            width:26px; 
            height:26px;
            text-align:center;
            border-radius:12px;
            padding-top:4px;
            font-size:110%;
            background-color:#EEEEEE;
            color:#2b2b2b;
            position:absolute;
            left:10px;
            top:2px;
        }
        .fa-play{
            padding-left:3px;
            padding-top:5px;
        }
        .fa-stop{
            padding-top:6px;
        }
        .fa-check{
        }
        .fa-th-list{
            padding-top:6px;
        }
        .fa-exclamation-circle{
            padding-top:5px;
        }
        .fa-refresh{
        }
        .fa-check{
            padding-top:6px;
        }
        .fa-question{
        }
        dl{
            position:relative;
            padding:5px;
            border:0px solid blue;
            padding-right:100px;
            background-color:#EEEEEE;
            margin:0px;
            dt{
                border:0px solid orange;
            }
            dd{
                position:absolute;
                right:5px;
                top:5px;
                border:0px solid blue;
            }
        }

    }

    .log_line_item_container:last-child{
        .vertLineContainer{
            display:none;
        }
    }


}
