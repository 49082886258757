.averageDurationDashboardModule{
    background-color:#2b7ee7;
    position:relative;
    margin:0px;
    padding:0px;
    padding-left:100px;
}
.averageDurationDashboardModule:hover{
    text-decoration:none;
}
    .averageDurationDashboardModule .icon-column{ 
        margin:0px;
        padding:0px;
        position:absolute;
        left:20px;
        top:15px;
        width:100px;
    }

        .averageDurationDashboardModule .left-icon{ 
            height:80px;
            width:80px;
            color:#ffffff;
        }

    .averageDurationDashboardModule .module-text-container{ 
        height:100px;
        color:#ffffff;
        background-color:#5d9cec;
        margin:0px;
        padding:10px 10px 10px 15px;
    }
        .averageDurationDashboardModule .module-text-container strong{ 
            display:block;
            white-space:nowrap;
            font-size:120%;
        }

        .size-xl
        .averageDurationDashboardModule .module-text-container strong{ 
            font-size:200%;
        }
