.fancy-select-dropdown-error-box{
    position:relative;
    min-height:23px;
    padding-top:3px;
    padding-left:10px;
}

.fancy-select-dropdown-field-outer-container{
    position:relative;
}

    .fancy-select-dropdown-field-container{
        position:relative;
    }

.fancy-select-dropdown-loading-spinner{
    position:relative;
}

.fancy-select-dropdown-loading-spinner i{
    position:absolute;
    left:8px;
    top:11px;
}

.fancy-select-dropdown-error-icon{
    position:absolute;
    right:43px;
    top:5px;
    border:0px solid red;
    z-index:1000;
}

