#sidebar-framework-container{
}
    #sidebar-container{
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        width:175px;
        background-color:#2b2b2b;
    }
    #content-body-container{
        margin-left:175px;
    }

@media (max-width: 575px) {
    #sidebar-container{
        width: 35px;
    }

    #content-body-container{
        margin-left:35px;
    }
}

#content-body-inner-container{
    // border:1px solid red;
    // padding:30px 20px 10px 20px;
    overflow-x: hidden;
}
    @media (max-width: 991px) {
        #content-body-inner-container{
            // padding:10px 0px 0px 0px;
        }
    }

#network-error-container{
    background-color:#ffffff;
    margin:100px auto 100px auto;
    width:500px;
    text-align:center;
}

#network-error-container h5{
    margin-top:20px;
}
