.code-editor-action-bar{
    background-color:#e8e8e8;
    position:relative;
    min-height:50px;

    .button-box{
        position:relative;
        padding:10px 0 10px 47px;
        // right:-2px;
        // top:10px;
        // width:155px;
        // height:35px;
        // text-align:right;
        border:0px solid red;

        .btn-outline-dark{
            background-color:#e8e8e8;
        }
        .btn-outline-dark:hover{
            color:#000;
        }

    }

    .action-bar-error{
        background-color:#e8e8e8;
        padding:10px 0 10px 0;
        margin:0px 120px 0px 50px;
        color:#dc3545;
        border:0px solid red;
    }

    .execution-log-action-bar-container{
        position:relative;
        padding:0px 0 0 47px;
        border:1px solid #e8e8e8;
        background-color:#e8e8e8;

        .execution-log-action-bar-clear-button-container{
            padding:0 10px 0px 0px;
            border:0px solid red;
            position:relative;
            top:-10px;
            background-color:#e8e8e8;
        }

    }

}

