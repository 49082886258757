.fancy-single-select-dropdown{
    background-color:#ffffff ! important;
    border: 0px solid green !important;
    box-shadow: none !important;
}

.fancy-single-select-dropdown__control{
    background-color:#ffffff ! important;
    border: 1px solid #eeeeee !important;
    box-shadow: none !important;
}