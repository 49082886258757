.totalCanaryTestsDashboardModuleContainer{
    background-color:#1e983b;
    position:relative;
    margin:0px;
    padding:0px;
    padding-left:95px;
}

.totalCanaryTestsDashboardModuleContainer:hover{
    text-decoration:none;
}

    .totalCanaryTestsDashboardModuleContainer .icon-column{ 
        margin:0px;
        padding:0px;
        position:absolute;
        left:10px;
        top:14px;
        width:100px;
    }

        .totalCanaryTestsDashboardModuleContainer .left-icon{ 
            background-image: url(/images/goldie_square_transparent_outline.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height:70px;
            width:70px;
        }

    .totalCanaryTestsDashboardModuleContainer .module-text-container{ 
        height:100px;
        color:#ffffff;
        background-color:#27c24c;
        margin:0px;
        padding:10px 10px 10px 15px;
    }
        .totalCanaryTestsDashboardModuleContainer .module-text-container strong{ 
            display:block;
            white-space:nowrap;
            font-size:120%;
        }

        .size-xl
        .totalCanaryTestsDashboardModuleContainer .module-text-container strong{ 
            font-size:200%;
        }
