.alert_rule_type_column{
    text-transform: capitalize;
}

.alert-rule-edit-button{
    visibility:hidden;
    -webkit-transition: none;
    transition: none;
}

.alert-rule-list-row:hover .alert-rule-edit-button{
    visibility:visible;
}
