.variable-vault-list-item-edit-row{
    // background-color:pink;
    margin-top:5px;
    padding-top:10px;
    padding-bottom:5px;
    border-top:1px solid #eee;

    .variable-vault-list-item-edit-key-col{
        // background-color:green;
        padding:0px 0 0 15px;
    }

    .variable-vault-list-item-edit-key-value-col{
        // background-color:blue;
        padding:0px 0 0 15px;
    }

    .variable-vault-list-item-edit-action-col{
        padding-top:3px;
        white-space:nowrap;
    }

    .variable-vault-list-item-edit-error-message{
        padding:0px 0 5px 15px;
        width:100%;
    }

}
