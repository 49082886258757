@import '~styles/sass_vars.scss';

.api-canary-data-table-outer-container{
    overflow-y: auto;

    .api-canary-data-table-container{
        padding:0px;
        border: $api-canary-data-table-border;
        border-radius: $api-canary-border-radius;
        overflow: hidden;

        .api-canary-data-table{

            margin:0px ! important;
            // border: $api-canary-data-table-border;
            // border:1px solid red ! important;
            border-collapse: collapse;
            overflow: hidden;
            border:0px ! important;

            thead{

                border:0px ! important;

                tr{
                    // border:3px solid green;
                    border:0px ! important;

                    th{
                        // border:3px solid blue;
                        border:0px ! important;
                        background-color:$module-border-color;

                    }
                }
            }

            thead.rawTableHeader{
                margin:0px;
                padding:0px;
                border:0px ! important;

                tr{
                    // border:3px solid pink;

                    th{
                        margin:0px;
                        padding:0px;
                        // border:0px ! important;
                        // border:3px solid blue;
                    }
                }

            }

            tbody{
                border:0px ! important;
            }

                tbody td{
                }
        }

        .api-canary-data-table-failure{

            margin:0px ! important;
            // border: $api-canary-data-table-border;
            border-collapse: collapse;
            overflow: hidden;

            thead{
                border:0px ! important;

                th{
                    border:0px ! important;
                    background-color:$module-border-color;
                }
            }
        }
        
        .pagination .pagination-page-link{
            width:100px;
            text-align:center;
        }
    }
}
