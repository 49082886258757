@import '~styles/sass_vars.scss';

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #DDDDDD ! important;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #DDDDDD ! important;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #DDDDDD ! important;
}
:-moz-placeholder { /* Firefox 18- */
    color: #DDDDDD ! important;
}

body{
    // background-color:#ffffff;
    // background-color:#eeeeee;
    background-color:#F3F3F3;
    // margin-top:55px;
    // font-family:"Open Sans",sans-serif;
    @extend %default-font-settings;
}

/* Bootstrap changes */

    .progress .bg-success {
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }

    .table-responsive {
        display: table;
    }


.datacenter-list-icon{
    color:$execution-node-icon-color;
}

.execution-node-list-icon{
    /* color:#fcc23a; */
    color:$execution-node-icon-color-active;
}

.pointer-clickable {
    cursor:pointer;
}
.pointer-disabled {
    cursor:not-allowed;
}
.pointer-loading {
    cursor:progress;
}

.fix-rounded-corners {
    overflow:hidden;
}
