@import '~styles/sass_vars.scss';

.overview-dashboard-top-container {
    border:0px solid red;
    padding:30px 20px 10px 20px;

    @media (max-width: 991px) {
        padding:10px 0px 0px 0px;
    }

    .datacenter-map-card{
        background-color:#000000;
    }

    .datacenter-map-container{
        position:relative;
        height:246px;
        background-image: url("/images/mercator_projection_map_400_246.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-color:#000000;
        margin: 10px 0px 10px 0px;

        dl{
            display:block;
            position:absolute;
            left:50%;
            // border:1px solid green;
            // left:10px;

            dt{
                display:block;
                width:10px;
                height:10px;
                border-radius:5px;
                background-color:#ffff00;
            }

            dd{
                // display:none;
                border:$api-canary-data-table-border;
                width:100px;
                height:50px;
                position:relative;
                left:-45px;
                bottom:63px;
                background-color:#ffffff;
                padding:3px 5px 2px 5px;
                text-align:center;
                font-size:80%;
            }
        }

        .AWS-US-EAST-1{
            top:125px;
            margin-left:-88px;
            width:88px;
        }

        .AWS-US-WEST-1{
            top:123px;
            margin-left:-137px;
            width:137px;
        }
    }

    .overview-dashboard-card {
         overflow: hidden;
         background-color: #ffffff;
         padding:0px;
         border:0px ! important;
    }

}

