@import '~styles/sass_vars.scss';

$map-height: 268px;


.dashboard-map-module-container{
    /* background-color: #000000; */
    background-color:#2b2b2b;
    height:$map-height;
    width: 100%;
}
    .dashboard-map-module-map-container{
        height: 347px;
        width: 100%;
        /* background-color: #000000; */
        background-color:#2b2b2b;
        padding:0px;
    }

        .dashboard-map-module-map-container .google_map_element{
            height:$map-height;
            width: 100%;
            background-color:#2b2b2b;
        }

.dashboard-map-module-placeholder-container{
    background-color:#2b2b2b;
    height:$map-height;
    width: 100%;
}
