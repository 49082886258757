.canary-test-form-body-container{
    margin-bottom:100px;

    .form-info-bubble{
        border:0px solid red;
        font-size:80%;

        i{
            display:none;
            color:orange;
            margin:0 5px 5px 0;
        }
    }
}
