.execution-node-list-data-table tr a:hover{
    text-decoration:none;
}

.execution-node-list-data-table .execution-node-edit-button {
    width:110px;
}

.execution-node-list-data-table .execution-node-edit-button-hover-hidden{
    visibility:hidden;
    -webkit-transition: none;
    transition: none;
}

.execution-node-list-data-table tr:hover .execution-node-edit-button-hover-hidden{
    visibility:visible;
}

.execution-node-list-data-table td .badge{
}

.execution-node-list-data-table td button{
    cursor:pointer;
}
