.executionResultDetailExecutionInformationContainer{
    padding:10px 10px 10px 20px;
}
    .executionResultDetailExecutionInformationContainer dl{
        margin:5px 0px 15px 0px;
        padding:0px 0px 0px 140px;
        position:relative;
    }
        .executionResultDetailExecutionInformationContainer dl dt{
            display:inline-block;
            margin:0px;
            padding:0px;
            width:130px;
            position:absolute;
            left:0px;
            top:0px;
        }
        .executionResultDetailExecutionInformationContainer dl dd{
            display:inline-block;
            margin:0px;
            padding:0px;
        }
