.rsm-marker{
    outline:none;
}
.rsm-marker:focus{
    outline:none;
}

.datacenter-map-module-container{
    position: relative;
    width:100%;
    // max-width:980px;
    margin:0 auto;
    height:330px;

    .zoom-button-container{
        position:absolute;
        left:20px;
        top:10px;
        width:30px;
        background-color:#ffffff;

        >div{
            border:1px solid #eee;
            border-radius:0px;
            width:30px;
            height:30px;
            text-align:center;
            font-size:20px;
            color:#555;
            border-top:0px;
        }
        div:first-child{
            border-top:1px solid #eee;
        }

    }

    circle:hover{
        cursor:pointer ! important;
    }

    .info_box_lable{
        font-weight:bold;
    }

    .info_box_close_button{
        font-size:18px;
        fill:#ccc;
        cursor:pointer ! important;
    }
    
    .bottom-bar-container{
        position:absolute;
        bottom:0px;
        left:0px;
        width:100%;
        background-color:#f7f8fa;
    }

}
