@import '~styles/sass_vars.scss';

.recent-failures-overview-module{
    padding:5px 0 20px 0;

    .list-group-item{
        border:0px;
        margin:0px;
        padding:0px ! important;

        a{
            text-decoration: none;
            color: #555555;
            display:block;
            padding:5px 0 0px 0;

            dl{
                margin:0px 10px 0px 20px;
                padding-bottom:0px;
                border-bottom:1px solid #eee;
                padding: 0 0 5px 0;

                strong{
                    display:block;
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow: ellipsis;
                }

                dt{
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    padding:8px 5px 0 0px;
                    font-weight:normal;
                }

                dd{
                    white-space:nowrap;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    border:0px solid green;
                    margin:0px;
                    white-space:nowrap;
                    padding:8px 0px 0 5px;
                    font-weight:normal;

                    .badge{
                        position:relative;
                        top:-4px;
                    }
                }

                .failure-time{
                    font-size:10px;
                    text-align:right;
                    flex: 0 0 75px;
                }

                .failure-badge{
                    flex: 0 0 55px;
                }


            }
        }

        a:hover{
            text-decoration: none;
            background-color: #eee ! important;
        }

    }

    .list-group-item:last-child dl{
        border-bottom:0px;
        padding-bottom:0px;
    }

}
