#dashboard-body-container{

    .dashboard-body-header-container{

        border:0px solid blue;
        margin:20px 0 20px 0;

        h1{
            /* border:0px solid red; */
            /* margin-bottom:20px; */
            font-size: 36px;
            font-weight:300 ! important;
            line-height:39.6px;
            margin:0px;
        }

        h2{
            border:0px solid red;
            margin-bottom:0px;
            font-size: 20px;
            font-weight:300 ! important;
        }

    }

}
