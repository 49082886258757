.canary-test-overview-module-container{
    position:relative;
}
    .canary-test-overview-module-container .doughnut-container{
        position:relative;
        background-colorx:green;
        width:116px;
        displayx:none;
        padding-bottom:5px;
        margin-bottom:5px;
    }
        .canary-test-overview-module-container .doughnut-container .success-percent-for-doughnut{
            position: absolute;
            left:26px;
            top:46px;
            font-weight:bold;
            border:0px solid red;
            width:70px;
            text-align:center;
            font-size:17px;
        }
    .canary-test-overview-module-container .overview-stats-container{
        position:absolute;
        display:block;
        background-colorx:blue;
        width:165px;
        left:135px;
        top:20px;
    }
        .canary-test-overview-module-container .overview-stats-container dl{
            width:180px;
            font-size:150%;
        }
            .canary-test-overview-module-container .overview-stats-container dt{
                display:inline;
            }
            .canary-test-overview-module-container .overview-stats-container dd{
                display:inline;
                padding-left:5px;
            }
