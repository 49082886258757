.my-team-list-item-edit-row{
    // background-color:pink;
    margin-top:5px;
    padding-top:10px;
    padding-bottom:5px;
    border-top:1px solid #eee;

    .my-team-list-item-edit-key-col{
        // background-color:green;
        padding:0px 0 0 15px;
        white-space:nowrap;
        overflow:hidden;

        div.placeholder{
            padding-top:10px;
        }
    }

    .my-team-list-item-edit-key-value-col{
        // background-color:blue;
        padding:0px 0 0 15px;
    }

    .my-team-list-item-edit-action-col{
        padding-top:3px;
        white-space:nowrap;
    }

    .my-team-list-item-edit-error-message{
        padding:0px 0 5px 15px;
        width:100%;
    }

}
