.execution-nodes-top-container{
    border:0px solid red;
    padding:30px 20px 10px 20px;
}
    @media (max-width: 991px) {
        .execution-nodes-top-container{
            padding:10px 0px 0px 0px;
        }
    }

