
$default-font-family: "Open Sans",sans-serif;
$default-font-size: 14px;
$default-line-height: 1.428571429;
$default-text-color: #555;

%default-font-settings {
    font-family:$default-font-family;
    font-size:$default-font-size;
    line-height: $default-line-height;
    color: $default-text-color;
}

$api-canary-orange: #fa753c;
$api-canary-yellow: #fcc23a;

$module-border-color: #f7f8fa;

$execution-node-icon-color-active: #e09d00;
$execution-node-icon-color: #010101;

$api-canary-data-table-border: 1px solid #e2e4e8;
// $api-canary-data-table-border: 1px solid red;

$api-canary-border-radius: .25rem ! important;
