.alert-rule-type-checkbox-outer-container .selected{
    background-color:#cccccc;
}

.alert-rule-type-checkbox-container{
    cursor: pointer;
    border-radius:.25rem;

    .alert-rule-type-checkbox{
        border:0px solid red;

        i{
            margin-top:6px;
            width:36px;
        }
    }
}
