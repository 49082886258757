.successFailureDashboardModule{
    background-color:#fa753c;
    position:relative;
    margin:0px;
    padding:0px;
    padding-left:100px;
}
.successFailureDashboardModule:hover{
    text-decoration:none;
}
    .successFailureDashboardModule .icon-column{ 
        margin:0px;
        padding:0px;
        position:absolute;
        left:23px;
        top:25px;
        width:100px;
    }

        .successFailureDashboardModule .left-icon{ 
            height:80px;
            width:80px;
            color:#ffffff;
        }

    .successFailureDashboardModule .module-text-container{ 
        height:100px;
        color:#ffffff;
        background-color:#ffa16f;
        margin:0px;
        padding:10px 10px 10px 15px;
    }
        .successFailureDashboardModule .module-text-container strong{ 
            display:block;
            white-space:nowrap;
            font-size:120%;
        }
        .size-xl
        .successFailureDashboardModule .module-text-container strong{ 
            font-size:200%;
        }
