    #sidebar-content-container{
        color:#999999;
    }
        #sidebar-logo-anchor{
            display:block;
            text-align:center;
            padding:20px 15px 10px 15px;
        }
            #sidebar-logo-anchor-build-label{
                position:absolute;
                z-index:10000;
                border:0px solid red;
                color:#ffffff;
                left:53px;
                top:51px;
            }

        .sidebar-top-anchor{
            padding:20px 20px 20px 20px;
            display:block;
            color:#999999;
        }

        .sidebar-top-anchor:hover{
            color:#999999;
            background-color:#222222;
            text-decoration:none;
        }

        @media (max-width: 575px) {
            .sidebar-top-anchor{
                padding:15px 5px 15px 5px;
                text-align:center;
            }
        }

            .sidebar-top-anchor .sidebar-archor-icon{
                width:26px; 
                height:26px;
                text-align:center;
                border-radius:12px;
                padding-top:4px;
                font-size:110%;
            }
                .active .sidebar-archor-icon{
                    /*
                    background-color:#fbc139;
                    */
                    background-color:#EEEEEE;
                    color:#2b2b2b;
                }

            /* Micro adjustments to icon placement on sidebar */
            .sidebar-top-anchor .sidebar-fa-desktop-ancor-icon{
                padding-top:6px;
            }
            .sidebar-top-anchor .sidebar-fa-th-list-ancor-icon{
                padding-top:5px;
            }


            .sidebar-top-anchor .sidebar-text{
            }
                .active .sidebar-text{
                    /*
                    color:#fbc139;
                    */
                    color:#EEEEEE;
                }

            @media (max-width: 575px) {
                .sidebar-top-anchor .sidebar-text{
                    display:none;
                }
            }

            .sidebar-top-anchor .fa-caret-left{
                font-size:80%;
                margin-left:5px;
                width:10px; 
                display:none;
            }
                #sidebar-content-container .collapsed .fa-caret-left{
                    display:inline;
                }
            @media (max-width: 575px) {
                .sidebar-top-anchor .fa-caret-left{
                    display:none;
                }
                #sidebar-content-container .collapsed .fa-caret-left{
                    display:none;
                }
            }

            .sidebar-top-anchor .fa-caret-down{
                font-size:80%;
                margin-left:5px;
                display:inline;
            }
                #sidebar-content-container .collapsed .fa-caret-down{
                    display:none;
                }
            @media (max-width: 575px) {
                .sidebar-top-anchor .fa-caret-down{
                    display:none;
                }
                    #sidebar-content-container .collapsed .fa-caret-down{
                        display:none;
                    }
            }
        
        .sidebar-sub-anchor{
            padding:10px 10px 10px 35px;
            display:block;
            color:#999999;
            background-color:#111111;
            font-size:100%;
        }
            .active-sub-anchor{
                font-weight:bold;
                color:#EEEEEE ! important;
            }

        .sidebar-sub-anchor:hover{
            color:#EEEEEE;
            text-decoration:none;
        }

        @media (max-width: 575px){
            .sidebar-sub-anchor{
                padding:10px 10px 10px 10px;
                white-space: nowrap;
                overflow:hidden;
                width:50px;
            }
        }

        .sidebar-last-anchor{
          border-top:3px solid #111111;
        }
