.my-team-add-new-email-search-form-container{
    border:0px solid red;
    max-width:350px;
    margin: 30px auto 0 auto;

    .search-box-container{
        border:0px solid red;
        position:relative;

        >i{
            position:absolute;
            left:3px;
            top:3px;
            border:0px solid red;
            width:30px;
            height:30px;
            color:#ddd;
        }
        input{
            padding-left:40px;
        }
    }
    .my-team-add-new-email-search-form-error-message{
        border:0px solid blue;
        padding-bottom:10px;
    }
}
