.fancy-input-error-box{
    position:relative;
    min-height:23px;
    padding-top:3px;
    padding-left:10px;
}

.fancy-input-loading-spinner{
    position:relative;
}

.fancy-input-loading-spinner i{
    position:absolute;
    left:8px;
    top:11px;
}

.fancy-form-element-outer-container{
    position:relative;
}

    .fancy-form-element-container{
        position:relative;
    }

