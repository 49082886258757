.alert-rule-type-checkbox-outer-container .selected:hover{
    background-color:#fafafa;
}
.alert-rule-type-checkbox-outer-container .selected{
    background-color:#fafafa;
    cursor:default;
}

.alert-rule-type-checkbox-outer-container:hover{
}

.alert-rule-type-checkbox-outer-container{
    border-radius: 5px;
}

.alert-rule-type-checkbox-container:hover{
    border: 1px solid #eeeeee;
}

.alert-rule-type-checkbox-container{
    background-color: #fdfdfd;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #fdfdfd;
}

.alert-rule-type-checkbox-container .alert-rule-type-checkbox{
    border: 0px solid red;
}
.alert-rule-type-checkbox-container .alert-rule-type-checkbox i{
    margin-top:6px;
    width:36px;
}
.alert-rule-type-checkbox-container .hidden-checkbox{
    display:none;
}
.alert-rule-type-checkbox-description{
    padding-top:6px;
}
