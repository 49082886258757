@import '~styles/sass_vars.scss';

.dashboard-module-container{
    overflow:hidden;
    position:relative;
    padding:0px;

    .dashboard-module-loading-bar-container{
        height: 1px;
        position: absolute;
        width:100%;
        top: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border: 0px solid red;
    }

    >.card-header{
        border:0px;
        // background-color:pink ! important;
        padding-bottom:0px;
        // padding-right:10px;

        .card-header-inner{
            padding-top:2px;
            border-bottom:1px solid #ddd;
            height:34px;
        }
    }

}
.dashboard-module-container-border{
    border: $api-canary-data-table-border;
    // border:0px ! important;
    // border: 1px solid green;
}

.dashboard-module-container-no-border{
    border:0px ! important;
}
