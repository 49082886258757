.time_out_check_box_container{
    position:relative;
    left:3px;
    top:-10px;

    input{
        position:relative;
        top:0px;
        margin-right:5px;
    }
}
