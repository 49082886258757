    .editor-label-column{
        white-space:nowrap;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        font-weight:bold;
    }
    @media (max-width: 991px) {
        .editor-label-column{
            -ms-flex: auto;
            flex: auto;
            text-align:left;
        }
    }
