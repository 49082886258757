.fancy-text-input-error-box{
    position:relative;
    min-height:23px;
    padding-top:3px;
    padding-left:10px;
}

.fancy-text-input-field-outer-container{
    position:relative;
}

    .fancy-text-input-field-container{
        position:relative;
    }

.fancy-text-input-loading-spinner{
    position:relative;
}

.fancy-text-input-loading-spinner i{
    position:absolute;
    left:8px;
    top:11px;
}

.fancy-text-input-error-icon{
    position:absolute;
    right:8px;
    top:5px;
}
