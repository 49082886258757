.my-team-create-new-account-form-container{
    border:0px solid red;
    max-width:350px;
    margin: 30px auto 0 auto;

    .my-team-add-create-new-account-form-error-message{
        border:0px solid blue;
        padding-bottom:10px;
    }
}
