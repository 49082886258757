@import '~styles/sass_vars.scss';

.latest-updates-module-body{
    padding:5px 20px 20px 20px;

    .media{
        color:#555555;
        text-decoration:none;

        img{
            width:150px;
        }
    }

    .media:hover{
        h5{
            text-decoration:underline;
        }
    }

    .list-group-item:last-child dl{
        border-bottom:0px;
        padding-bottom:0px;
    }

}
